















import { Component, Vue } from "vue-property-decorator";

import { ProcessPayment, ReplenishmentForm } from "@/data/replenishment";
import ReplenishmentResource from "@/resources/replenishments-resource";

@Component
export default class CreateReplenishmentFormComponent extends Vue {
  form = new ReplenishmentForm();
  loading: boolean = false;
  async doReplenishment() {
    await this.$validator.validate("form.*").then(async (res: boolean) => {
      if (res) {
        this.loading = true;
        await ReplenishmentResource.create(this.form)
          .then(async data => {
            return await ReplenishmentResource.getPaymentData(data.id);
          })
          .then(res => {
            const paymentData = new ProcessPayment(res);
            paymentData.submit();
          });
      }
    });
  }
  toInvoice(): void {
    this.$router.push({ name: "invoice", query: { amount: this.form.amount } });
  }
}
