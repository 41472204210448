import { BaseResource } from "./base-resource";
import {
  IReplenishment,
  IReplenishmentCreationRequest,
  IReplenishmentPaymentData
} from "@/data/replenishment";

export default class ReplenishmentResource {
  static create(
    request: IReplenishmentCreationRequest
  ): Promise<IReplenishment> {
    return BaseResource.tryPost(ReplenishmentUrl.create, request);
  }
  static getPaymentData(id: string): Promise<IReplenishmentPaymentData> {
    return BaseResource.tryGet(
      ReplenishmentUrl.paymentData.replace("{id}", id)
    );
  }
}

enum ReplenishmentUrl {
  create = "/v1/wallet/replenishments",
  paymentData = "/v1/wallet/replenishments/{id}/payment-data"
}
